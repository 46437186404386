import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";

import img_gatsby from "assets/images/gatsby-astronaut.png";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query CloudinaryImages {
      allCloudinaryMedia {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `);
  const clImages = data.allCloudinaryMedia.edges;
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Emma Fujiko</title>
      </Helmet>
      <Container>
        <div className="images-wrapper">
          {clImages.map((image, index) => (
            <div key={`${index}-cl`} className="image">
              <img src={image.node.secure_url} />
            </div>
          ))}
        </div>
        <div className="email-wrapper">
          <a href="mailto:emmafujiko@gmail.com">emmafujiko@gmail.com</a>
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
